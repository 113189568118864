@import 'src/shared/style/colors.scss';

.nat-cat-maps {
  padding-left: 50px;

  .profile-control {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .zoom-slider {
    margin: 5px 5px 10px 0px;
  }
}
