#sources-grid {
  border: 0.5px solid #797d7d;
  font-size: 12px;
  > div {
    margin-top: -1px;
    margin-left: -1px;
    padding: 5px;
    text-align: center;
    border-top: 0.5px solid #797d7d;
    border-left: 0.5px solid #797d7d;
  }
}
