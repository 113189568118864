@import 'src/shared/style/colors.scss';

.report-natural-catastrophe-table {
  margin-bottom: 20px;
  page-break-inside: avoid;

  .profile-name {
    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        .report-natcat-icon {
          margin-right: 15px;
        }
      }
      .risk-grade {
        font-size: 18px;
      }
    }
    border-bottom: 1px solid $color-grey;
  }

  .results {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .results-container {
      width: 100%;
      padding: 0px 10px 10px 0px;
      .legends-subtitle {
        font-size: 12px;
      }
      .map {
        margin: 10px 0px;
        max-width: 100%;
      }
    }

    .legends {
      .legend-tiles {
        border: 1px solid $color-grey;
        padding: 5px 10px;
        width: 290px;

        .legend-tile {
          display: flex;
          flex-direction: row;

          min-height: 24px;
          align-items: center;

          .legend-fill {
            width: 40px;
            height: 12px;
            border: 1px solid $color-grey;
          }
          .legend-title {
            margin-left: 5px;
            font-size: 12px;
          }
        }

        .risk-classification {
          border-top: 1px solid $color-grey;
          margin-top: 10px;
          padding-top: 10px;
          font-size: 13px;
          font-weight: 700;
          color: darken($color-grey, 30%);
          text-transform: capitalize;

          .risk-item {
            font-size: 12px;
            text-transform: capitalize;
            font-weight: 500;
            &:not(.first) {
              &:before {
                content: ', ';
              }
            }
          }
        }
      }
    }
  }

  .sources {
    font-size: 13px;
    color: darken($color-grey, 30%);
    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
