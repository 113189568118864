@import 'src/shared/style/colors.scss';

.pac-container:after {
  background-image: none !important;
  height: 0px;
  padding: 0;
  margin: 0;
}

.selected-location {
  color: $color-primary;
  font-size: 16px;
  font-weight: 700;
}

.report-address-search {
  display: flex;
  margin-bottom: 20px;

  .autocomplete {
    width: 100%;
  }

  .address-search {
    text-overflow: ellipsis;
    width: 100%;
    height: 34px;
    padding: 6px 11px;
    font-size: 15px;
    border: 1px solid #d9d9d9;

    &::-webkit-input-placeholder {
      color: #bfbfbf;
    }

    &:hover {
      outline: 2px solid #d9d9d9;
    }

    &:focus {
      outline: 2px solid #d9d9d9;
    }
  }

  .address-search-button {
    min-width: 20%;
    margin-left: 15px;
  }
}
