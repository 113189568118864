@import 'src/shared/style/colors.scss';

.iprofile-report-preview-cover {
  height: 100%;
  position: relative;
  overflow: hidden;

  .overlay {
    position: absolute;
    width: 100%;
    height: 1906px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
    transform-origin: 100% 0;
    transform: rotate(36.6deg);
    z-index: 0;
  }

  .pop {
    position: relative;
    z-index: 1;
  }

  &-header {
    padding: 30px 50px;
    color: $color-white;
    height: 640px;

    .company-logo {
      width: 170px;
      height: 80px;
      background-image: url(./assets/iprofile-logo.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 15px;
    }
    .insured-business-name {
      color: $color-white;
    }
    .title {
      font-weight: 700;
      font-size: 48px;
      margin: 20px 0px;
      width: 420px;
      color: $color-white;
    }
    .industry,
    .address {
      margin-top: 10px;
      color: $color-white;
    }
  }

  &-body {
    background-image: url(./assets/iprofile-cover-main.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 500px;
    width: 840px;

    &.natcat {
      background-image: url(./assets/iprofile-cover-natcat.jpg);
    }
  }

  &-footer {
    display: flex;
    width: 100%;
    padding: 0 40px;
    justify-content: space-between;
    background-color: $color-white;
    .logo {
      height: 35px;
    }
  }
}
