@import 'src/shared/style/colors.scss';

.iprofile-report-preview-back-cover {
  position: relative;
  height: 100%;

  &-info {
    position: absolute;
    bottom: 200px;
    height: 180px;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;

    .logos-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;
      .logo {
        width: 120px;
        margin: 0 0 0 15px;
      }
    }
  }
}
