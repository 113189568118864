@import 'src/shared/style/colors.scss';

.report-list {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .report-list-item {
    background-color: $color-white;
    padding: 10px 20px;
    margin-bottom: 10px;
    border: 1px solid $color-grey;
    cursor: pointer;

    &.dashed {
      border-style: dashed;
    }

    &.disabled {
      h3,
      svg {
        color: $color-grey;
      }
    }

    .report-list-item-meta {
      display: flex;
      align-items: center;
      &:focus {
        outline: none;
      }

      &-icon {
        background-color: transparent;
        @include themify($themes) {
          color: themed('colorBase');
        }
      }

      &-content {
        margin: 0 10px;
        flex: 1;
      }

      h3 {
        font-size: 16px;
      }
    }

    &.small {
      padding: 8px 0 8px 40px;
      margin: 0;
      border: none;

      h3 {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .report-list {
    .report-list-item {
      padding: 10px;

      .report-list-item-meta {
        &-content {
          margin: 0 5px;
        }
      }
    }
  }
}
