@import 'src/shared/style/colors.scss';

.natcat-profiles {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  h3 {
    margin: 10px 0px;
  }

  .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .location-search-container {
    background-color: $color-white;
    padding: 20px;
    box-shadow: 1px 1px 3px 0px $color-grey;
    margin: 20px 0px;
  }

  .natcat-profile-header {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      &-inner {
        margin: 5px;
      }
    }

    &-inner {
      display: flex;
      width: 100%;
      align-items: center;

      &.title {
        font-size: 18px;
        font-weight: 600;
        .natcat-profile-icon {
          margin-right: 25px;
        }
      }
      &.grade {
        display: flex;
        justify-content: space-between;
        .status-bar {
          max-width: 60%;
        }
        .grade-description {
          font-weight: 600;
        }
      }
    }
  }

  .results {
    display: flex;
    justify-content: space-between;
    padding: 10px 55px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 10px;
    }

    img {
      max-width: 100%;
    }

    .results-container {
      width: 100%;
      padding: 0px 20px 10px 0px;

      .legends-subtitle {
        font-size: 13px;
        color: darken($color-grey, 30%);
      }

      .map {
        margin: 10px 0px;
        max-width: 550px;
      }

      .sources {
        font-size: 13px;
        color: darken($color-grey, 30%);

        a {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .legends-container {
      min-width: 35%;
      .legend-tiles {
        border: 1px solid $color-grey;
        padding: 5px 10px;
        margin-bottom: 15px;
        .legend-tile {
          display: flex;
          flex-direction: row;
          min-height: 30px;
          align-items: center;
          .legend-fill {
            width: 40px;
            height: 12px;
            border: 1px solid $color-grey;
          }
          .legend-title {
            margin-left: 5px;
            font-size: 12px;
          }
        }
        .risk-classification {
          border-top: 1px solid $color-grey;
          margin-top: 10px;
          padding-top: 10px;
          font-size: 13px;
          font-weight: 700;
          color: darken($color-grey, 30%);
          text-transform: capitalize;

          .risk-item {
            text-transform: capitalize;
            font-weight: 500;
            &:not(.first) {
              &:before {
                content: ', ';
              }
            }
          }
        }
      }

      .zoom-slider {
        margin: 5px 0px;
      }
    }
  }

  .natcat-layer {
    box-shadow: 1px 1px 3px 0px $color-grey;
    .natcat-layer-panel {
      margin: 5px;
    }
  }
}
