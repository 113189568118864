@import '../utils/colorPalette.scss';

$color-base: #212223;
$color-primary: #006dad;
$color-secondary: #2a377a;
$color-third: #ffffff;
$color-text: colorPalette($color-base, 5);
$color-light-text: $color-third;
$color-primary-hover: colorPalette($color-primary, 5);
$color-primary-active: colorPalette($color-primary, 7);
$color-secondary-light: colorPalette($color-secondary, 4);
$color-secondary-hover: colorPalette($color-secondary, 5);
$color-secondary-active: colorPalette($color-secondary, 7);
$color-title: $color-base;
$color-header: $color-base;
$color-footer: $color-secondary;
$color-gradient-1: $color-third;
$color-gradient-2: colorPalette($color-base, 2);
$color-gradient-title: $color-secondary;
$color-gradient-text: $color-text;
$color-sider-menu: $color-text;
$color-sider-menu-hover: $color-secondary;
$color-sider-menu-selected: colorPalette($color-sider-menu, 1);
$color-table-header: $color-secondary;
$color-card: $color-secondary;

$iprofile: (
  colorBase: $color-base,
  colorPrimary: $color-primary,
  colorPrimaryHover: $color-primary-hover,
  colorPrimaryActive: $color-primary-active,
  colorSecondary: $color-secondary,
  colorSecondaryLight: $color-secondary-light,
  colorSecondaryHover: $color-secondary-hover,
  colorSecondaryActive: $color-secondary-active,
  colorTitle: $color-title,
  colorHeader: $color-header,
  colorFooter: $color-footer,
  colorGradient1: $color-gradient-1,
  colorGradient2: $color-gradient-2,
  colorGradientTitle: $color-gradient-title,
  colorGradientText: $color-gradient-text,
  colorSiderMenu: $color-sider-menu,
  colorSiderMenuHover: $color-sider-menu-hover,
  colorSiderMenuSelected: $color-sider-menu-selected,
  colorTableHeader: $color-table-header,
  colorCard: $color-card,
);
