@import '../themes/iprofile.scss';

$light-font-color: #fefefe;

.theme-iprofile {
  a {
    color: inherit;
  }

  b {
    color: $color-primary;
  }

  .ant-menu.main-menu {
    .ant-menu-item,
    .ant-menu-submenu {
      color: $light-font-color;
      &:hover {
        color: darken($light-font-color, 20%);
      }
    }
  }

  .ant-input {
    &:focus {
      outline: 1px solid $color-primary;
      border: 1px solid $color-primary;
    }
    &:hover {
      outline: 1px solid $color-primary;
      border: 1px solid $color-primary;
    }
  }

  .ant-spin-text {
    color: $color-primary;
  }

  .footer {
    color: $light-font-color;
    a {
      color: $light-font-color;
    }
  }
}
