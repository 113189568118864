@import 'src/shared/style/colors.scss';

.dashboard-natcat {
  padding: 40px;

  h2 {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.4;
    text-transform: uppercase;
  }

  &-icons {
    text-align: center;
    i {
      font-size: 55px;
      margin: 15px;
      color: $color-primary;
    }
  }
  &-search-btn {
    width: 100%;
  }
}
